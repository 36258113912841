import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const SectionWaves = () => {
	return (
		<div className="relative sm:-mt-40 bg-gradient-to-r from-sky-600 to-cyan-400 z-10">
			<svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink">
				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
						<path
							d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
							opacity="0.100000001"></path>
						<path
							d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
							opacity="0.100000001"></path>
						<path
							d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
							id="Path-4" opacity="0.200000003"></path>
					</g>
					<g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
						<path
							d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z">
						</path>
					</g>
				</g>
			</svg>
		</div>
	)
}

const StarterSection = () => {
	const [isLoaded, setIsLoaded] = useState(false)
	useEffect(() => {
		setTimeout(() => { setIsLoaded(true) }, 200)
	}, [])

	return (
		<section className="bg-gradient-to-r from-sky-600 to-cyan-400 shadow-md">
			<div className={`flex flex-col md:flex-row py-32 transition duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'}`}>
				<div className="md:w-1/2 sm:px-24 flex flex-col justify-center">
					<div className="bg-white rounded-xl shadow-md p-8 z-20">
						<p className="w-full text-gray-600 text-xs md:text-sm">FOR LIGHTNING SPEED STARTUPS</p>
						<h3 className="text-4xl font-semibold mb-4">Deliver your MVP in weeks or days.</h3>
						<p className="text-lg mb-4">
							Established agencies like STRV typically command higher rates for iOS and Android apps, and their standard delivery timeline ranges from 3 to 6 months. In certain scenarios, like you've got a potential customer cooling off, the need for a proof-of-concept expires - it's not feasible to wait months in a lot of cases.
							<br />
							<br />
							Opportunities appear and disappear rapidly. Immediate access to an MVP helps you prevent missed opportunities or loss of market traction to competitors.
						</p>
						<ol className="list-decimal pl-6">
							<li>iOS / Android Apps</li>
							<li>Asset Creation</li>
							<li>Scheduled Check-Ins</li>
							<li>Odd-Hour Availability</li>
							<li>App Store Finalization & Launch</li>
						</ol>
						<div className="flex items-left justify-start">
							<a
								className="mx-auto lg:mx-0 hover:underline gradient bg-sky-400 text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
								href={'https://calendly.com/build-e4zi/30min'}
								target="_blank" rel="noreferrer"
							>
								Let's Talk
							</a>
						</div>
					</div>
				</div>
				<div className="md:w-1/2 md:pl-4 pt-8 pb-8 sm:pb-0 sm:pt-0 flex justify-center items-top">
					<div style={{ backgroundColor: 'rgba(255,255,255,0.5)' }} className="w-[400px] h-[400px] aspect-square rounded-full z-20">
						<img style={{ position: 'absolute', maxWidth: '100%', width: '500px' }} src="/mvp.svg" alt="Startup" />
					</div>
				</div>
			</div>
		</section>

	)
}

const GrowthSection = () => {
	return (
		<section className="bg-white p-8 shadow-md flex flex-col-reverse md:flex-row">
			<div className="md:w-1/2 md:pl-4 pt-8 sm:pt-0 flex justify-center items-center relative">
				<div className="w-64 h-64 bg-gray-200 rounded-full">
					<img style={{ width: '400px', position: 'absolute', left: 0 }} src="/growth.svg" alt="Growth" />
				</div>
			</div>
			<div className="md:w-1/2 md:pr-4 flex flex-col justify-center">
				<p className="w-full text-gray-600 text-xs md:text-sm">FOR TRACTION</p>
				<h3 className="text-4xl font-semibold mb-4">Prepare for Fundraising</h3>
				<p className="text-lg mb-4">
					Very rarely, an idea is enough for funding. In most cases, ideas are cheap and it's really the execution or proof of market pull that attracts VC money.
					<br />
					<br />
					Denim helps you get your MVP ready for demos and pitches, can help build out your pitch deck and set technical direction, and in some cases can help interview and hire candidates for supporting the software we deliver.
				</p>
				<ol className="list-decimal pl-6">
					<li>MVP Delivery</li>
					<li>Demo / Pitch Coaching</li>
					<li>Friends & Family Email Prompts</li>
					<li>Establish Technical Direction</li>
				</ol>
				<div className="flex items-left justify-start">
					<a href="https://calendly.com/build-e4zi/30min" target="_blank" rel="noreferrer"
						className="mx-auto lg:mx-0 hover:underline gradient bg-sky-400 text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
						Get Started
					</a>
				</div>
			</div>
		</section>

	)
}

const EnterpriseSection = () => {
	return (
		<section className="bg-gray-100 p-8 rounded-lg shadow-md flex flex-col md:flex-row overflow-hidden">
			{/* Enterprise Category */}
			<div className="md:w-1/2 sm:px-24 flex flex-col justify-center">
				<p className="w-full text-gray-600 text-xs md:text-sm">FOR ENTERPRISE</p>
				<h3 className="text-4xl font-semibold mb-4">Comprehensive Solutions</h3>
				<p className="text-lg mb-4">
					Large enterprises benefit from Denim's consultative approach:
				</p>
				<ol className="list-decimal pl-6">
					<li>Discovery Meeting</li>
					<li>Requirement Analysis</li>
					<li>Scope Definition</li>
					<li>Budget & Expectations Alignment</li>
					<li>Agreement & Commencement</li>
					<li>Ongoing Collaboration</li>
				</ol>
				<div className="flex items-left justify-start">
					<a href="https://calendly.com/build-e4zi/30min" target="_blank" rel="noreferrer"
						className="mx-auto lg:mx-0 hover:underline gradient bg-sky-800 text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
						Schedule A Call
					</a>
				</div>
			</div>
			<div className="md:w-1/2 md:pl-4 pt-8 sm:pt-0 flex justify-center items-center relative">
				<div className="min-w-64 min-h-64 bg-gray-200 rounded-full absolute z-10"></div>
				<img className="object-cover z-20" src="/enterprise.svg" alt="Enterprise" />
			</div>
		</section>
	);
};


export default function Startups() {
	return (
		<>
			<Navbar />
			<StarterSection />
			<SectionWaves />
			<GrowthSection />
			<EnterpriseSection />
			<Footer />
		</>

	)
}
