import React from 'react'
import denim from './jeans.svg'

export default function Footer() {
    return (
        <footer className="bg-white">
            <div className="container mx-auto px-8">
                <div className="w-full flex flex-col md:flex-row py-6">
                    <div className="flex-1 mb-6 text-black items-center flex justify-center">
                        <a className="text-sky-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl justify-center items-center" href="/" style={{width: '120px', textAlign: 'center'}}>
                            <img alt={"Denim"} style={{ display: 'block', padding: 6, overflow: 'hidden' }} className="bg-white hover:bg-gray-100" height={120} width={120} src={denim} />
                            <div style={{width: '120px', textAlign: 'center'}}>DENIM</div>
                        </a>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">More Stuff</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="/get-started" className="no-underline hover:underline text-gray-800 hover:text-pink-500">How It Works</a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="/startups" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Successful MVPs</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <ul className="list-reset mb-6 md:mt-12">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="mailto:build@with-denim.com" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Support</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <ul className="list-reset mb-6 md:mt-12">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="/privacy" target="_blank" rel="noreferrer" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Privacy</a>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Social</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="#" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Facebook</a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="#" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Linkedin</a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="#" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Twitter</a>
                            </li>
                        </ul>
                    </div> */}
                    {/* <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Company</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="#" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Official Blog</a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="#" className="no-underline hover:underline text-gray-800 hover:text-pink-500">About Us</a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="#" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Contact</a>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </footer>
    )
}