import React from 'react'
import Navbar from './Navbar'

export default function Stories(){
    return (
        <>
            <Navbar />
            <section>
                Stuff goes here
            </section>
        </>
    )
}
