import React from 'react'
import './index.css'
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

import App from './App'
import Process from './Process'
import Stories from './Stories'
import Startups from './Startups'

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{
		path: "get-started",
		element: <Process />,
	},
	{
		path: "stories",
		element: <Stories />
	},
	{
		path: "startups",
		element: <Startups />
	}
]);

createRoot(document.getElementById("root")).render(
	<RouterProvider router={router} />
);
