import React, { useState } from 'react'
import denim from './jeans.svg'
import { useLocation } from 'react-router-dom'

export default function Navbar() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const location = useLocation();

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	return (
		<nav id="header" style={{ backgroundColor: 'rgba(15, 23, 42, 0.6)' }} className="fixed w-full z-30 top-0 text-white backdrop-blur-md">
			<div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
				<div className="pl-4 flex items-center">
					<a className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="/" style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
						<img alt={"Denim"} style={{ display: 'inline', borderRadius: '50%', padding: 6, overflow: 'hidden' }} className="bg-white hover:bg-gray-100" height={40} width={40} src={denim} />
						<span style={{ marginLeft: '10px' }}>DENIM</span>
					</a>
				</div>
				<div className="block lg:hidden pr-4">
					<button id="nav-toggle" onClick={toggleMenu} className="flex items-center p-1 text-gray-300 hover:text-blue-200 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
						<svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
							<title>Menu</title>
							<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
						</svg>
					</button>
				</div>
				<div className={`w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 text-black p-4 lg:p-0 z-20 lg:backgrop-blur-none ${isMenuOpen ? '' : 'hidden'}`} id="nav-content">
					<ul className="list-reset lg:flex justify-end flex-1 items-center mb-4 sm:mb-0">
						<li className="mr-3">
							<a className={`inline-block py-2 px-4 text-white ${location.pathname === "/" && 'font-bold'} no-underline`} href="/">Home</a>
						</li>
						<li className="mr-3">
							<a className={`inline-block text-white no-underline hover:text-gray-200 hover:text-underline py-2 px-4 ${location.pathname === "/get-started" && 'font-bold'}`}
								href="/get-started">How It Works</a>
						</li>
						<li className="mr-3">
							<a className={`inline-block text-white no-underline hover:text-gray-200 hover:text-underline py-2 px-4 ${location.pathname === "/startups" && 'font-bold'}`}
								href="/startups">Successful MVPs</a>
						</li>
						{/* <li className="mr-3">
							<a className={`inline-block text-white no-underline hover:text-gray-200 hover:text-underline py-2 px-4 ${location.pathname === "/stories" && 'font-bold'}`}
								href="/stories">Success</a>
						</li> */}
					</ul>
					<a id="navAction" href="https://calendly.com/build-e4zi/30min" target="_blank" rel="noreferrer" className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
						Try It
					</a>
				</div>
			</div>
		</nav>
	)
}
